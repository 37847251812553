<template>
  <v-container class="login">
    <v-row no-gutters>
      <header class="col-12 content-header">
        <h1 class="content-title">
          <template v-if="$vuetify.breakpoint.smAndUp">
            Log in
          </template>
          <template v-else>
            <auth-toggle-header />
          </template>
        </h1>
      </header>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 py-0">
        <auth-form-layout
          submit-button-text="Login"
          @submit-action="signInAndRedirect(item)"
        >
          <v-col cols="12">
            <form-input
              ref="email"
              v-model="item.email"
              label="Email"
              :rules="rules.email"
              autocomplete="email"
            />
          </v-col>
          <v-col cols="12">
            <form-input
              v-model="item.password"
              label="Password"
              type="password"
              :rules="rules.password"
              autocomplete="current-password"
            />
          </v-col>
          <v-col
            cols="12"
            class="text-right mb-2"
          >
            Forgot password?

            <a
              class="forgot-password text-decoration-underline"
              style="font-size: 16px;"
              @click="sendPasswordLink(item)"
            >
              Reset
            </a>
          </v-col>

          <template v-slot:afterSubmitButton>
            <v-col class="col-12">
              <div class="login-form-divider text-center">
                <span>or</span>
              </div>
            </v-col>
            <v-col class="col-12 d-flex justify-center py-0">
              <auth-doxyme />
            </v-col>
            <v-col class="col-12">
              <div class="login-form-divider text-center">
                <span>or</span>
              </div>
            </v-col>
            <v-col class="col-12 d-flex justify-center py-0">
              <auth-google />
            </v-col>
          </template>
        </auth-form-layout>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mt-8"
    >
      <v-col class="col-12 content-footer text-center">
        Don't have an account?
        <router-link
          :to="{ name: 'signUp' }"
          class="text-decoration-underline"
        >
          Sign up
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mdiCloseBoxOutline, mdiOrnament} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import AuthFormLayout from '@/components/auth/AuthFormLayout'
import AuthGoogle from '@/components/auth/AuthGoogle'
import AuthDoxyme from '@/components/auth/AuthDoxyme'
import AuthToggleHeader from '@/components/auth/AuthToggleHeader'
import FormInput from '@/components/form/FormInput'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'SignIn',
  components: {
    AuthFormLayout,
    AuthGoogle,
    AuthDoxyme,
    AuthToggleHeader,
    FormInput,
  },
  data() {
    return {
      item: {
        email: null,
        password: null
      },
      rules: {
        email: [v => !!v || 'Email is required', v => emailValidation(v)],
        password: [v => !!v || 'Password is required']
      },
      mdiCloseBoxOutline,
      overlay: false
    }
  },
  mounted() {
    // this.$root.$on('loading', (loading) => {
    //   console.log('are we loading?', loading)
    //   this.overlay = loading
    // })
  },
  methods: {
    ...mapActions('authentication', ['signIn', 'resetPassword']),
    async signInAndRedirect(item) {
      const user = await this.signIn(item)

      if (!user) {
        // User login error is handled in the authentication/signIn action (a notice is dispatched)
        return
      } 
      if (!Object.prototype.hasOwnProperty.call(user, 'signInAt')) {
        // First sign in - immediately after signing in, the signInAt prop is set to the current date
        // New users won't have this prop in vuex (unless they reload the page)
        this.$router.replace({
          name: 'welcome'
        })
      } else {
        await this.$router.push({
          name: 'dashboard'
        }).catch(() => {})
      }
      
    },
    async sendPasswordLink(item) {
      this.resetPassword(item)
      // there seems to be no functional way to set focus to the input
      // leaving this here until vuetify fixes the bug or we figure out
      // another way around it
      // setTimeout(() => {
      //   this.$refs.email.$el.focus()
      // })
    }
  },
  
}
</script>

<style scoped lang="scss">
  .login {
    margin: 1rem;
    max-width: calc(100% - 2rem);
  }

  @media(min-width: 769px) {
    .login {
      margin: 0 auto;
      max-width: 768px;
    }
  }
  .content-title {
    margin-top: 2rem;
    text-align: left;
  }

  .snackbar {
    .icon {
      &--success {
        margin-bottom: -7px;
        margin-right: 12px;
      }

      &--error {
        margin-bottom: -7px;
        margin-right: 12px;
      }
    }

    &--text {
      color: #192333;
    }
  }

  
</style>